import Two454 from "../assets/2454.png";
import abuDhabiMedia from "../assets/abudhabimedia.jpg";
import accenture from "../assets/accenture-logo.png";
import arabianAdventure from "../assets/arabianAdvLogo.png";
import asianSchool from "../assets/asianschool.jpg";
import intlIndianSchool from "../assets/indianschool.jpg";
import emirates from "../assets/emirates.jpg";
import grantHyat from "../assets/grent-hyat.jpg";
export const clients = [
  {
    label: "emirates",
    image: emirates,
  },
  {
    label: "Two454",
    image: Two454,
  },
  {
    label: "abuDhabiMedia",
    image: abuDhabiMedia,
  },
  {
    label: "accenture",
    image: accenture,
  },
  {
    label: "arabianAdventure",
    image: arabianAdventure,
  },
  {
    label: "asianSchool",
    image: asianSchool,
  },
  {
    label: "intlIndianSchool",
    image: intlIndianSchool,
  },
  {
    label: "grantHyat",
    image: grantHyat,
  },
];
